// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import CMSActionInterface from "https://framerusercontent.com/modules/2S4XFDGrkqLDHzD4YKsL/xgMO7iTVBdrDTCa6FH2k/CMSActionInterface.js";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const CMSActionInterfaceFonts = getFonts(CMSActionInterface);
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["u5aBYgghT"];

const serializationHash = "framer-sllSW"

const variantClassNames = {u5aBYgghT: "framer-v-1t66lyz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.12, 0.23, 0.5, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, slug, width, ...props}) => { return {...props, fztyqXWTo: slug ?? props.fztyqXWTo} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;slug?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fztyqXWTo, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "u5aBYgghT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1t66lyz", className, classNames)} data-framer-name={"Done"} initial={variant} layoutDependency={layoutDependency} layoutId={"u5aBYgghT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 70, borderBottomRightRadius: 70, borderTopLeftRadius: 70, borderTopRightRadius: 70, ...style}}><motion.div className={"framer-1c7x111-container"} layoutDependency={layoutDependency} layoutId={"s02uLoqjt-container"}><CMSActionInterface height={"100%"} id={"s02uLoqjt"} layoutId={"s02uLoqjt"} slug={fztyqXWTo} width={"100%"}/></motion.div><motion.div className={"framer-kvbu79-container"} layoutDependency={layoutDependency} layoutId={"DXuwuZVgq-container"}><Phosphor color={"var(--token-db045c8d-82bf-47c4-8c5a-9446a159a946, rgb(240, 240, 240)) /* {\"name\":\"Heading\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"Check"} id={"DXuwuZVgq"} layoutId={"DXuwuZVgq"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-sllSW[data-border=\"true\"]::after, .framer-sllSW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sllSW.framer-1yt98zw, .framer-sllSW .framer-1yt98zw { display: block; }", ".framer-sllSW.framer-1t66lyz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: visible; padding: 0px 2px 2px 2px; position: relative; width: min-content; }", ".framer-sllSW .framer-1c7x111-container { flex: none; height: auto; left: 0px; position: absolute; top: 0px; width: auto; z-index: 1; }", ".framer-sllSW .framer-kvbu79-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sllSW.framer-1t66lyz { gap: 0px; } .framer-sllSW.framer-1t66lyz > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-sllSW.framer-1t66lyz > :first-child { margin-left: 0px; } .framer-sllSW.framer-1t66lyz > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"fztyqXWTo":"slug"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerK0sCoGIwO: React.ComponentType<Props> = withCSS(Component, css, "framer-sllSW") as typeof Component;
export default FramerK0sCoGIwO;

FramerK0sCoGIwO.displayName = "CMS / Lesson Complete Icon";

FramerK0sCoGIwO.defaultProps = {height: 22, width: 24};

addPropertyControls(FramerK0sCoGIwO, {fztyqXWTo: {defaultValue: "", title: "Slug", type: ControlType.String}} as any)

addFonts(FramerK0sCoGIwO, [...CMSActionInterfaceFonts, ...PhosphorFonts])